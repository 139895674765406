<template>
  <div class="container" style="margin-bottom: 30px;">
    <center>
      <div class="sticky-top">
        <img src="images/new-header.png" class="img-responsive" style="width: 90%;" />
      </div>
    </center>
    <center>

      <div class="card-container" v-show='alerts.length > 0' style="margin-bottom: 30px;"> <!-- Error Alerts -->
        <div class="card border border-primary" style="width: 90%;">
          <div class="card-body">
            <div v-for='(alert, index) in alerts' :key='alert.msg' :class='alert.type' role="alert">
              {{ alert.msg }}
              <button type="btn btn-danger" class="close" data-dismiss="alert" aria-label="Close" @click='closeAlert(index)'>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-container"> <!-- Contact Form -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <center>
              <h5 class="card-title">Please complete the following information:</h5>
            </center>
            <form class="form-inline card-text">
              <div class="form-group fg-spacer">
                <label for="name" class="label-spacer"><strong>Your name: </strong></label>
                <input id="name" type="text" class="form-control" placeholder="Please enter your name" v-model='name' required />            
              </div>
              <div class="form-group fg-spacer">
                <label for="phone" class="label-spacer"><strong>Your contact phone #: </strong></label>
                <input id="phone" type="text" class="form-control" placeholder="Please enter your phone #" v-model='phone' required />            
              </div>
              <div class="form-group fg-spacer">
                <label for="location" class="label-spacer"><strong>Your location name: </strong></label>
                <input id="location" type="text" class="form-control" placeholder="Please enter your location" v-model='location.name' required />            
              </div>
              <div class="form-group fg-spacer">
                <label for="address1" class="label-spacer"><strong>Your location address: </strong></label>
                <input id="address1" type="text" class="form-control" placeholder="Address 1" style="margin-bottom: 10px;" v-model='location.address1' required /> 
                <input id="address2" type="text" class="form-control" placeholder="Address 2 (Optional)" style="margin-bottom: 10px;" v-model='location.address2' />
                <div class="row">
                  <div class="col-md-5">
                    <input id="city" type="text" class="form-control" placeholder="City" style="margin-bottom: 10px;" v-model='location.city' required />
                  </div>
                  <div class="col-md-3">
                    <select id="state" class="form-control" style="margin-bottom: 10px;" v-model='location.state'>
                      <option value="">Select a state</option>
                      <option v-for='state in states' :key='state.abbreviation' :value='state.abbreviation'>{{ state.abbreviation }}</option>  
                    </select>
                  </div>
                  <div class="col-md-4">
                    <input id="zip" type="text" class="form-control" placeholder="Zip code" v-model='location.zip' required /> 
                  </div>
                </div>          
              </div>
              <div class="form-group fg-spacer">
                <label for="ups" class="label-spacer"><strong>Berlin Packaging UPS/FedEx Acct# of your location: </strong></label>
                <input id="ups" type="text" class="form-control" placeholder="Please enter your UPS acct#" v-model='ups' />            
              </div>
              <div class="form-group fg-spacer">
                <label for="plus_freight" class="label-spacer"><strong>Would you like the order to ship "plus freight" instead?: </strong></label>
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <select id="plus_freight" class="form-control" style="margin-bottom: 10px;" v-model='plusFreight' >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>  
                    </select>
                  </div>
                  <div class="col-md-4"></div>
                </div>           
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="card-container" style="margin-top: 30px;"> <!-- truck driver checklist -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <h5 class="card-title">Berlin Packaging Electric Truck Driver Checklist</h5>
            <p class="card-text">Book is approximately 4 1/4" wide x 7 3/16" high of 50 sets of 2 part NCR (white, canary) with cardboard backing 
              and a wraparound cover stapled at the top.  <strong>Minimum Order: 10 books</strong>
            </p>
            <div class="row fg-spacer"> <!-- Images -->
              <div class="col-md-6">
                <img src="images/truck-driver-checklist/truck-driver-checklist-1.png" class="img-responsive" style="width: 90%;" />
              </div>
              <div class="col-md-6">
                <img src="images/truck-driver-checklist/truck-driver-checklist-2.png" class="img-responsive" style="width: 90%;" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <p class="card-text price label-spacer" style="padding-top: 8px;"><strong>{{ truckDriverChecklist.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-1">
                <form class="form">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Qty" v-model='truckDriverChecklist.qty' @keyup='calculateTruckDriverChecklist' />
                  </div>
                </form>
              </div>
              <div class="col-md-9">
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ truckDriverChecklist.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div class="card-container" style="margin-top: 30px;"> <!-- mens vest #1 -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img src="images/mens-vest-1/mens-vest-1.png" class="img-responsive" style="width: 90%;" />
                <p class="card-text price" style="padding-top: 8px;"><strong>{{ mensVest1.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-6">
                <table class="table"> <!-- Price Qty/Table -->
                  <thead>
                    <tr>
                      <th class="col-md-6" style="text-align: right;">Size</th>
                      <th class="col-md-6">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p class="card-text tabletop-pad">Small:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.sQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">Medium:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.mQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">Large:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.lQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.xLQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">2XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.xxLQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">3XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.xxxLQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">4XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.xxxxLQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">5XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest1.xxxxxLQty' @keyup='calculateMensVest1' /></td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ mensVest1.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>


      <div class="card-container" style="margin-top: 30px;"> <!-- mens vest #2 -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img src="images/mens-vest-2/mens-vest-2.png" class="img-responsive" style="width: 90%;" />
                <p class="card-text price" style="padding-top: 8px;"><strong>{{ mensVest2.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-6">
                <table class="table"> <!-- Price Qty/Table -->
                  <thead>
                    <tr>
                      <th class="col-md-6" style="text-align: right;">Size</th>
                      <th class="col-md-6">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p class="card-text tabletop-pad">Small:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.sQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">Medium:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.mQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">Large:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.lQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.xLQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">2XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.xxLQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">3XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.xxxLQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">4XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.xxxxLQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">5XLarge:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='mensVest2.xxxxxLQty' @keyup='calculateMensVest2' /></td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ mensVest2.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div class="card-container" style="margin-top: 30px;"> <!-- womens vest #1 -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img src="images/womens-vest-1/womens-vest-1.png" class="img-responsive" style="width: 90%;" />
                <p class="card-text price" style="padding-top: 8px;"><strong>{{ womensVest1.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-6">
                <table class="table"> <!-- Price Qty/Table -->
                  <thead>
                    <tr>
                      <th class="col-md-6" style="text-align: right;">Size</th>
                      <th class="col-md-6">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p class="card-text tabletop-pad">S/M:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest1.sMQty' @keyup='calculateWomensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">L/XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest1.lXlQty' @keyup='calculateWomensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">2XL/3XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest1.xxLxxxLQty' @keyup='calculateWomensVest1' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">4XL/5XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest1.xxxxLxxxxxLQty' @keyup='calculateWomensVest1' /></td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ womensVest1.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div class="card-container" style="margin-top: 30px;"> <!-- womens vest #2 -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img src="images/womens-vest-2/womens-vest-2.png" class="img-responsive" style="width: 90%;" />
                <p class="card-text price" style="padding-top: 8px;"><strong>{{ womensVest2.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-6">
                <table class="table"> <!-- Price Qty/Table -->
                  <thead>
                    <tr>
                      <th class="col-md-6" style="text-align: right;">Size</th>
                      <th class="col-md-6">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p class="card-text tabletop-pad">S/M:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest2.sMQty' @keyup='calculateWomensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">L/XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest2.lXlQty' @keyup='calculateWomensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">2XL/3XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest2.xxLxxxLQty' @keyup='calculateWomensVest2' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">4XL/5XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='womensVest2.xxxxLxxxxxLQty' @keyup='calculateWomensVest2' /></td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ womensVest2.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div class="card-container" style="margin-top: 30px;"> <!-- machine operator vest -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img src="images/operator-vest/operator-vest.png" class="img-responsive" style="width: 90%;" />
                <p class="card-text price" style="padding-top: 8px;"><strong>{{ machineOperatorVest.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-6">
                <table class="table"> <!-- Price Qty/Table -->
                  <thead>
                    <tr>
                      <th class="col-md-6" style="text-align: right;">Size</th>
                      <th class="col-md-6">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p class="card-text tabletop-pad">MD/XL:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='machineOperatorVest.mdxlQty' @keyup='calculateMachineOperatorVest' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">2X/5X:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='machineOperatorVest.twoxfivexQty' @keyup='calculateMachineOperatorVest' /></td>
                    </tr>
                    <tr>
                      <td><p class="card-text tabletop-pad">5X:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='machineOperatorVest.fivexQty' @keyup='calculateMachineOperatorVest' /></td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ machineOperatorVest.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>


      <div class="card-container" style="margin-top: 30px;"> <!-- Knife -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <h5 class="card-title">Recessed/Concealed Fixed Blade Safety Utility Knife</h5>
            <div class="row">
              <div class="col-md-3">
                <img src="images/knife/knife.png" class="img-responsive" style="width: 90%;" />
                <p class="card-text price" style="padding-top: 8px;"><strong>{{ knife.price | currency }} ea.</strong></p>
              </div>
              <div class="col-md-6">
                <table class="table"> <!-- Price Qty/Table -->
                  <thead>
                    <tr>
                      <th class="col-md-6" style="text-align: right;">Size</th>
                      <th class="col-md-6">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p class="card-text tabletop-pad">One Size:</p></td>
                      <td><input type="text" class="form-control" placeholder="Qty" v-model='knife.qty' @keyup='calculateKnife' /></td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text price label-spacer text-success" style="padding-top: 8px;"><strong>Subtotal: {{ knife.subtotal | currency }}</strong></p>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div class="card-container" style="margin-top: 30px;"> <!-- Grand Total -->
        <div class="card border border-primary" style="width: 90%">
          <div class="card-body">
            <h5 class="card-title">Grand Total</h5>
            <p class="card-text text-success price">{{ grandTotal | currency }}</p>
            <button class="btn btn-primary" @click='submit'>Submit your order</button>
          </div>
        </div>      
      </div>

    </center>
  </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import States from '@/services/States.service.js';
import Api from '@/services/Api.service.js';

export default {
  name: 'Berlin',
  data () {
    return {
      alerts: [],
      states: States.states,
      name: null,
      phone: null,
      location: {
        name: null,
        address1: null,
        address2: null,
        city: null,
        state: '',
        zip: null
      },
      ups: null,
      plusFreight: 'no',
      truckDriverChecklist: {
        price: 9.95,
        qty: null,
        subtotal: 0
      },
      mensVest1: {
        price: 9.15,
        sQty: null,
        mQty: null,
        lQty: null,
        xLQty: null,
        xxLQty: null,
        xxxLQty: null,
        xxxxLQty: null,
        xxxxxLQty: null,
        subtotal: 0
      },
      mensVest2: {
        price: 9.15,
        sQty: null,
        mQty: null,
        lQty: null,
        xLQty: null,
        xxLQty: null,
        xxxLQty: null,
        xxxxLQty: null,
        xxxxxLQty: null,
        subtotal: 0
      },
      womensVest1: {
        price: 16.30,
        sMQty: null,
        lXlQty: null,
        xxLxxxLQty: null,
        xxxxLxxxxxLQty: null,
        subtotal: 0
      },
      womensVest2: {
        price: 16.30,
        sMQty: null,
        lXlQty: null,
        xxLxxxLQty: null,
        xxxxLxxxxxLQty: null,
        subtotal: 0
      },
      machineOperatorVest: {
        price: 19.40,
        mdxlQty: null,
        twoxfivexQty: null,
        fivexQty: null,
        subtotal: 0
      },
      knife: {
        price: 21.00,
        qty: null,
        subtotal: 0
      },
      grandTotal: 0
    }
  },
  filters: {
    currency: function (value) {
      value = value.toFixed(2)
      value+=''
      var x = value.split('.')
      var x1 = x[0]
      var x2 = x.length > 1 ? '.' + x[1] : ''
      var rgx = /(\d+)(\d{3})/
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2')
      }
      var val = x1 + x2
      return '$' + val
    }  
  },
  methods: {
    closeAlert (index) {
      this.alerts.splice(index, 1)
    },
    changeFreight () {
      if (this.plusFreight === 'yes') {
        this.ups = null;
      }
    },
    calculateGrandTotal () {
      this.grandTotal = this.truckDriverChecklist.subtotal + this.mensVest1.subtotal + this.mensVest2.subtotal + this.womensVest1.subtotal + this.womensVest2.subtotal + this.machineOperatorVest.subtotal + this.knife.subtotal;
    },
    calculateTruckDriverChecklist () {
      this.truckDriverChecklist.subtotal = this.truckDriverChecklist.qty * this.truckDriverChecklist.price;
      this.calculateGrandTotal();
    },
    calculateMensVest1 () {
      this.mensVest1.subtotal = (this.mensVest1.sQty * this.mensVest1.price) + (this.mensVest1.mQty * this.mensVest1.price) + (this.mensVest1.lQty * this.mensVest1.price) + (this.mensVest1.xLQty * this.mensVest1.price) + (this.mensVest1.xxLQty * this.mensVest1.price) + (this.mensVest1.xxxLQty * this.mensVest1.price) + (this.mensVest1.xxxxLQty * this.mensVest1.price) + (this.mensVest1.xxxxxLQty * this.mensVest1.price);
      this.calculateGrandTotal();
    },
    calculateMensVest2 () {
      this.mensVest2.subtotal = (this.mensVest2.sQty * this.mensVest2.price) + (this.mensVest2.mQty * this.mensVest2.price) + (this.mensVest2.lQty * this.mensVest2.price) + (this.mensVest2.xLQty * this.mensVest2.price) + (this.mensVest2.xxLQty * this.mensVest2.price) + (this.mensVest2.xxxLQty * this.mensVest2.price) + (this.mensVest2.xxxxLQty * this.mensVest2.price) + (this.mensVest2.xxxxxLQty * this.mensVest2.price);
      this.calculateGrandTotal();
    },
    calculateWomensVest1 () {
      this.womensVest1.subtotal = (this.womensVest1.sMQty * this.womensVest1.price) + (this.womensVest1.lXlQty * this.womensVest1.price) + (this.womensVest1.xxLxxxLQty * this.womensVest1.price) + (this.womensVest1.xxxxLxxxxxLQty * this.womensVest1.price);
      this.calculateGrandTotal();
    },
    calculateWomensVest2 () {
      this.womensVest2.subtotal = (this.womensVest2.sMQty * this.womensVest2.price) + (this.womensVest2.lXlQty * this.womensVest2.price) + (this.womensVest2.xxLxxxLQty * this.womensVest2.price) + (this.womensVest2.xxxxLxxxxxLQty * this.womensVest2.price);
      this.calculateGrandTotal();
    },
    calculateMachineOperatorVest () {
      this.machineOperatorVest.subtotal = (this.machineOperatorVest.mdxlQty * this.machineOperatorVest.price) + (this.machineOperatorVest.twoxfivexQty * this.machineOperatorVest.price) + (this.machineOperatorVest.fivexQty * this.machineOperatorVest.price);
      this.calculateGrandTotal();
    },
    calculateKnife () {
      this.knife.subtotal = this.knife.qty * this.knife.price;
      this.calculateGrandTotal();
    },
    async submit (evt) {
      evt.preventDefault();
      if (!this.name || this.name === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a name' });
        return window.scrollTo(0,0);
      }
      if (!this.phone || this.phone === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a valid phone number' });
        return window.scrollTo(0,0);
      }
      if (!this.location.name || this.location.name === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a Berlin Packaging location' });
        return window.scrollTo(0,0);
      }
      if (!this.location.address1 || this.location.address1 === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a valid address' });
        return window.scrollTo(0,0);
      }
      if (!this.location.city || this.location.city === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a valid city' });
        return window.scrollTo(0,0);
      }
      if (!this.location.state || this.location.state === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a valid state' });
        return window.scrollTo(0,0);
      }
      if (!this.location.zip || this.location.zip === '') {
        this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must enter a Berlin Packaging location' });
        return window.scrollTo(0,0);
      }
      if (this.plusFreight === 'no') {
        if (!this.ups || this.ups === '') {
          this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'Please enter your location\'s UPS Acct#' });
          return window.scrollTo(0,0);
        }
      }
      const payload = {
        info: {
          name: this.name,
          phone: this.phone,
          location: this.location,
          ups: this.ups,
          plusFreight: this.plusFreight,
          truckDriverChecklist: this.truckDriverChecklist,
          mensVest1: this.mensVest1,
          mensVest2: this.mensVest2,
          womensVest1: this.womensVest1,
          womensVest2: this.womensVest2,
          machineOperatorVest: this.machineOperatorVest,
          knife: this.knife,
          grandTotal: this.grandTotal
        }
      }
      Api.submit(payload)
      .then(res => {
        console.log(res.data);
        if (res.data.success === true) {
          this.$router.push('/thank-you')
        } else {
          this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: 'Something went wrong.  Your order could not be submitted at this time' });
          return window.scrollTo(0,0);          
        }
      })
      .catch(err => {
        this.alerts.push({ type: 'alert alert-danger alert-dismissible fade show', msg: JSON.stringify(err) });
        return window.scrollTo(0,0);
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.label-spacer {
  margin-bottom: 3px;
}
.fg-spacer {
  margin-bottom: 15px;
}
.price {
  font-size: 1.16em;
}
.tabletop-pad {
  padding-top: 8px;
  text-align: right;
}
</style>
